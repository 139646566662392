define('ember-impagination/components/impagination-dataset', ['exports', 'ember', 'ember-impagination/templates/components/impagination-dataset', 'impagination/dataset'], function (exports, _ember, _emberImpaginationTemplatesComponentsImpaginationDataset, _impaginationDataset) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberImpaginationTemplatesComponentsImpaginationDataset['default'],
    'load-horizon': 2,
    'unload-horizon': Infinity,
    'page-size': null,
    'fetch': null,
    'filter': null,
    datasetState: null,
    queue: [],

    records: _ember['default'].computed('datasetState', function () {
      return CollectionInterface.create({
        datasetState: this.get('dataset.state'),
        dataset: this.get('dataset')
      });
    }),

    dataset: _ember['default'].computed('page-size', 'load-horizon', 'unload-horizon', 'fetch', 'on-observe', 'filter', function () {
      var _this = this;

      var round = Math.round;
      return new _impaginationDataset['default']({
        pageSize: round(this.get('page-size')),
        loadHorizon: round(this.get('load-horizon')),
        unloadHorizon: round(this.get('unload-horizon')),
        fetch: this.get('fetch'),
        filter: this.get('filter'),
        observe: function observe(datasetState) {
          _ember['default'].run(function () {
            _this.safeSet('datasetState', datasetState);
            _this.sendAction('on-observe', _this.get('records'), _this._actions);
          });
        }
      });
    }),

    setInitialState: _ember['default'].observer('dataset', function () {
      this.set('datasetState', this.get('dataset.state'));
    }),

    safeSet: function safeSet(key, value) {
      if (!this.isDestroyed) {
        this.set(key, value);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setInitialState();
      var readOffset = Math.round(this.get('read-offset')) || 0;
      this.get('dataset').setReadOffset(readOffset);
    },

    actions: {
      reset: function reset(offset) {
        offset = offset >= 0 ? offset : 0;
        this.get('dataset').reset(offset);
      },

      reload: function reload(offset) {
        offset = offset >= 0 ? offset : this.get('datasetState.readOffset');
        this.get('dataset').reload(offset);
      },

      refilter: function refilter() {
        this.get('dataset').refilter();
      },

      setReadOffset: function setReadOffset(offset) {
        offset = offset >= 0 ? offset : this.get('datasetState.readOffset');
        this.get('dataset').setReadOffset(offset);
      }
    }
  });

  var PagesInterface = _ember['default'].Object.extend(_ember['default'].Array, {
    objectAt: function objectAt(i) {
      var page = this.pages[i];
      return page || undefined;
    }
  });

  var CollectionInterface = _ember['default'].Object.extend(_ember['default'].Array, {
    init: function init() {
      this._super.apply(this, arguments);

      this.length = this.datasetState.length;
    },

    pages: _ember['default'].computed('datasetState.pages', function () {
      return PagesInterface.create({
        length: this.get('datasetState.pages.length'),
        pages: this.get('datasetState.pages')
      });
    }),

    readOffset: _ember['default'].computed.readOnly('datasetState.readOffset'),

    objectAt: function objectAt(i) {
      var record = this.datasetState.get(i);
      _ember['default'].run.debounce(this, 'objectReadAt', i, 1, true);
      return record;
    },

    objectReadAt: function objectReadAt(offset) {
      this.get('dataset').setReadOffset(offset);
    },

    slice: function slice(start, end) {
      if (typeof start !== "number") {
        start = 0;
      }

      if (typeof end !== "number") {
        end = this.datasetState.length;
      }

      var length = end - start;

      if (length < 0) {
        return [];
      }
      _ember['default'].run.schedule('afterRender', this, 'objectReadAt', start);

      var sliced = [];

      for (var i = 0; i < length; i++) {
        sliced.push(this.datasetState.get(start + i));
      }

      return sliced;
    }
  });
});