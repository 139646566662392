define('ember-es-adapter/serializers/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][lesson]:[normalizeFindAllResponse]');
      var hits = payload.hits;
      payload = this.mapMultiResponse(hits);

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][lesson]:[normalizeQueryResponse]');
      var hits = payload.hits;
      payload = this.mapMultiResponse(hits);

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][lesson]:[normalizeSingleResponse]');

      payload = {
        data: {
          id: payload._id,
          type: payload._type,
          attributes: payload._source
        },
        meta: {
          index: payload._index,
          exists: payload.exists,
          version: payload._version
        }
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    mapMultiResponse: function mapMultiResponse(hits) {
      return {
        data: hits.hits.map(function (hit) {
          return {
            type: hit._type,
            id: hit._id,
            attributes: hit._source
          };
        }),
        meta: {
          total: hits.total
        }
      };
    }
  });
});