define('impagination/record', ['exports', 'impagination/page'], function (exports, _impaginationPage) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var Record = (function () {
    function Record() {
      var page = arguments.length <= 0 || arguments[0] === undefined ? new _impaginationPage['default']() : arguments[0];
      var content = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
      var index = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      _classCallCheck(this, Record);

      this.page = page;
      this.content = content;
      this.index = index;
      if (page.error) {
        this.error = page.error;
      }
    }

    _createClass(Record, [{
      key: 'isRequested',
      get: function get() {
        return this.page.isRequested;
      }
    }, {
      key: 'isPending',
      get: function get() {
        return this.page.isPending;
      }
    }, {
      key: 'isResolved',
      get: function get() {
        return this.page.isResolved;
      }
    }, {
      key: 'isRejected',
      get: function get() {
        return this.page.isRejected;
      }
    }]);

    return Record;
  })();

  exports['default'] = Record;
});