define('ember-es-adapter/services/aws-sign', ['exports', 'ember', 'ember-es-adapter/utils/cryptojs-hasher', 'ember-get-config'], function (exports, _ember, _emberEsAdapterUtilsCryptojsHasher, _emberGetConfig) {
  'use strict';

  var EsAdapter = _emberGetConfig['default'].EsAdapter;

  exports['default'] = _ember['default'].Service.extend({
    reqParams: _ember['default'].A(),

    signReq: function signReq() {
      var kDate, kRegion, kService, kSigning;
      var reqParams = this.get('reqParams');

      reqParams.clear();

      var data = {
        kDate: kDate,
        kRegion: kRegion,
        kService: kService,
        kSigning: kSigning
      };

      reqParams.pushObject({ kDate: this.signKey() });

      //console.log(reqParams);
      return data;
    },

    convertDateAmazon: function convertDateAmazon(date, short) {
      date = new Date(EsAdapter.date) || new Date();

      var result = date.toISOString().replace(/[:\-]|\.\d{3}/g, '').substr(0, 17);
      if (short) {
        return result.substr(0, 8);
      }

      return result;
    },

    //signKey(date) {
    //  console.log('signKey');
    //  date = this.convertDateAmazon(EsAdapter.dateStamp || date, true);

    //  return hmac( 'AWS4' + EsAdapter.secretKey, date, {hexOutput: false});
    //},

    signRegion: function signRegion(kDate) {
      var region = EsAdapter.regionName;

      return (0, _emberEsAdapterUtilsCryptojsHasher.hmac)(kDate, region, { hexOutput: false, textInput: false });
    },

    signKey: function signKey() {
      //console.log('signKey');
      var key = 'AWS4' + EsAdapter.secretKey,
          date = this.convertDateAmazon(EsAdapter.date || new Date(), true);

      return (0, _emberEsAdapterUtilsCryptojsHasher.hmac)(key, date);
    },

    signMe: function signMe(array) {
      array = array || [];
      //console.log('signMe');
      //console.log(array);

      var date = this.convertDateAmazon(EsAdapter.date || new Date());
      return date;
    },

    signService: function signService(service, signedRegion) {
      service = EsAdapter.serviceName || service;

      var crypto = (0, _emberEsAdapterUtilsCryptojsHasher.hmac)(signedRegion, service, { hexOutput: false, textInput: false });

      return crypto;
    },

    calculateSignature: function calculateSignature() {
      console.log(EsAdapter);

      var signKey = (0, _emberEsAdapterUtilsCryptojsHasher.hmac)((0, _emberEsAdapterUtilsCryptojsHasher.hmac)(this.signRegion(null, this.signDate()), "ES", { hexOutput: false, textInput: false }), 'aws4_request', { hexOutput: false, textInput: false });
      console.log(signKey);
      //ws.signature = hmac(signKey, ws.stringToSign, {textInput: false});
    }

  });
});