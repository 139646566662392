define('ember-es-adapter/utils/cryptojs-hasher', ['exports', 'cryptojs', 'cryptojs/sha256', 'cryptojs/hmac-sha256', 'ember-es-adapter/utils/extend'], function (exports, _cryptojs, _cryptojsSha256, _cryptojsHmacSha256, _emberEsAdapterUtilsExtend) {
  'use strict';

  function hmac(key, input, options) {
    var hmac = new _cryptojsHmacSha256['default'](input, key, { asBytes: true });
    options = (0, _emberEsAdapterUtilsExtend['default'])({ hexOutput: false, textInput: true }, options);

    return options.hexOutput ? toHex(hmac) : hmac;
  }

  function hash(input, options) {
    var hash = (0, _cryptojsSha256['default'])(input);
    options = (0, _emberEsAdapterUtilsExtend['default'])({ hexOutput: false, textInput: true }, options);

    return options.hexOutput ? toHex(hash) : hash;
  }

  function toHex(input) {
    return input.toString(_cryptojs['default'].enc.Hex);
  }

  exports.hash = hash;
  exports.hmac = hmac;
  exports.toHex = toHex;
});