define('impagination/page', ['exports', 'impagination/record'], function (exports, _impaginationRecord) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
      throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  // Array.prototype.fill
  function fill(array, value) {
    for (var i = 0; i < array.length; i++) {
      array[i] = value;
    }
    return array;
  }

  var UnrequestedPage = (function () {
    function UnrequestedPage() {
      var offset = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var size = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];

      _classCallCheck(this, UnrequestedPage);

      this.offset = offset;
      this.size = size;
      this.data = fill(new Array(size), null);
    }

    UnrequestedPage.prototype.request = function request() {
      return new PendingPage(this);
    };

    UnrequestedPage.prototype.unload = function unload() {
      return this;
    };

    _createClass(UnrequestedPage, [{
      key: 'isRequested',
      get: function get() {
        return this.isPending || this.isResolved || this.isRejected;
      }
    }, {
      key: 'isPending',
      get: function get() {
        return false;
      }
    }, {
      key: 'isResolved',
      get: function get() {
        return false;
      }
    }, {
      key: 'isRejected',
      get: function get() {
        return false;
      }
    }, {
      key: 'isSettled',
      get: function get() {
        return !this.isPending && (this.isResolved || this.isRejected);
      }
    }, {
      key: 'records',
      get: function get() {
        if (!this._records) {
          this._records = this.data.map(function (content, index) {
            return new _impaginationRecord['default'](this, content, index);
          }, this);
        }
        return this._records;
      }
    }]);

    return UnrequestedPage;
  })();

  var PendingPage = (function (_UnrequestedPage) {
    _inherits(PendingPage, _UnrequestedPage);

    function PendingPage(unrequested) {
      _classCallCheck(this, PendingPage);

      _UnrequestedPage.call(this, unrequested.offset, unrequested.size);
    }

    PendingPage.prototype.resolve = function resolve(records, filterCallback) {
      return new ResolvedPage(this, records, filterCallback);
    };

    PendingPage.prototype.reject = function reject(error) {
      return new RejectedPage(this, error);
    };

    PendingPage.prototype.request = function request() {
      return this;
    };

    PendingPage.prototype.unload = function unload() {
      return new UnrequestedPage(this.offset, this.size);
    };

    _createClass(PendingPage, [{
      key: 'isPending',
      get: function get() {
        return true;
      }
    }]);

    return PendingPage;
  })(UnrequestedPage);

  var ResolvedPage = (function (_PendingPage) {
    _inherits(ResolvedPage, _PendingPage);

    function ResolvedPage(pending, data, filterCallback) {
      _classCallCheck(this, ResolvedPage);

      _PendingPage.call(this, pending);
      this.unfilteredData = data;
      this.filterCallback = filterCallback || function () {
        return true;
      };
      this.data = this.unfilteredData.filter(this.filterCallback);
    }

    _createClass(ResolvedPage, [{
      key: 'isPending',
      get: function get() {
        return false;
      }
    }, {
      key: 'isResolved',
      get: function get() {
        return true;
      }
    }, {
      key: 'isSettled',
      get: function get() {
        return true;
      }
    }]);

    return ResolvedPage;
  })(PendingPage);

  var RejectedPage = (function (_PendingPage2) {
    _inherits(RejectedPage, _PendingPage2);

    function RejectedPage(pending, error) {
      _classCallCheck(this, RejectedPage);

      _PendingPage2.call(this, pending);
      this.error = error;
    }

    _createClass(RejectedPage, [{
      key: 'isPending',
      get: function get() {
        return false;
      }
    }, {
      key: 'isRejected',
      get: function get() {
        return true;
      }
    }, {
      key: 'isSettled',
      get: function get() {
        return true;
      }
    }]);

    return RejectedPage;
  })(PendingPage);

  exports['default'] = UnrequestedPage;
});