define('ember-data-save-relationships/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({

    serializeRelationship: function serializeRelationship(snapshot, data, rel) {

      var relKind = rel.kind;
      var relKey = rel.key;

      if (this.get('attrs.' + relKey + '.serialize') === true) {

        data.relationships = data.relationships || {};
        var key = this.keyForRelationship(relKey, relKind, 'serialize');
        data.relationships[key] = data.relationships[key] || {};

        if (relKind === "belongsTo") {
          data.relationships[key].data = this.serializeRecord(snapshot.belongsTo(relKey));
        }

        if (relKind === "hasMany") {
          data.relationships[key].data = snapshot.hasMany(relKey).map(this.serializeRecord);
        }
      }
    },

    serializeRecord: function serializeRecord(obj) {

      if (!obj) {
        return null;
      }

      var serialized = obj.serialize();

      if (obj.id) {
        serialized.data.id = obj.id;
      } else {
        if (!serialized.data.attributes) {
          serialized.data.attributes = {};
        }
        serialized.data.attributes.__id__ = obj.record.get('_internalModel')[_ember['default'].GUID_KEY];
      }

      // do not allow embedded relationships
      delete serialized.data.relationships;

      return serialized.data;
    },

    serializeHasMany: function serializeHasMany() {
      this._super.apply(this, arguments);
      this.serializeRelationship.apply(this, arguments);
    },

    serializeBelongsTo: function serializeBelongsTo() {
      this._super.apply(this, arguments);
      this.serializeRelationship.apply(this, arguments);
    },

    updateRecord: function updateRecord(json, store) {

      if (!json.attributes) {
        // return non-attribute (id/type only) JSON intact
        return json;
      }

      var record = store.peekAll(json.type).filterBy('currentState.stateName', "root.loaded.created.uncommitted").findBy('_internalModel.' + _ember['default'].GUID_KEY, json.attributes.__id__);

      if (record) {
        // record.unloadRecord();
        record.set('id', json.id);
        record._internalModel.flushChangedAttributes();
        record._internalModel.adapterWillCommit();
        store.didSaveRecord(record._internalModel);
        // store.push({ data: json });
      }

      return json;
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, modelName, obj) {
      var _this = this;

      var rels = obj.data.relationships || [];

      Object.keys(rels).forEach(function (rel) {
        var relationshipData = rels[rel].data;
        if (Array.isArray(relationshipData)) {
          // hasMany
          relationshipData = relationshipData.map(function (json) {
            json.type = _ember['default'].String.singularize(json.type);
            _this.updateRecord(json, store);
          });
        } else {
          // belongsTo
          relationshipData.type = _ember['default'].String.singularize(relationshipData.type);
          relationshipData = _this.updateRecord(relationshipData, store);
        }
      });

      return this._super(store, modelName, obj);
    }

  });
});