define('ember-es-adapter/adapters/adapter', ['exports', 'ember-data/adapters/json-api', 'ember-es-adapter/utils/es-query-builder', 'ember-es-adapter/utils/extend', 'ember', 'ember-get-config'], function (exports, _emberDataAdaptersJsonApi, _emberEsAdapterUtilsEsQueryBuilder, _emberEsAdapterUtilsExtend, _ember, _emberGetConfig) {
  'use strict';

  var environment = _emberGetConfig['default'].environment;

  exports['default'] = _emberDataAdaptersJsonApi['default'].extend({
    config: environment,

    query: function query(store, type, params) {
      var url = [this.buildURL(type.modelName), '_search'].join('/');
      var query = _ember['default'].get(params, 'esQuery') || null,
          esParams = _ember['default'].get(params, 'esParams') || null,
          _params = this.filter(params); // Filter out params we don't know how to handle

      // No EsQuery object was supplied, so we'll make a new one. This
      // allows us to build the query outside of the adapter if needed.
      if (_ember['default'].isEmpty(query)) {
        // Initiate new instance with params if supplied
        // Inject _params into params object
        var es = new _emberEsAdapterUtilsEsQueryBuilder['default']((0, _emberEsAdapterUtilsExtend['default'])(esParams, _params));

        // Add in query if supplied in params;
        if (params.query) {
          es.addBool({ "query_string": { "query": params.query } });
        }
        query = es.buildQuery();
      }

      return fetch(url, {
        method: "POST",
        body: JSON.stringify(query)
      }).then(function (resp) {
        return resp.json();
      });
    },

    findAll: function findAll(store, type) {
      var url = [this.buildURL(type.modelName), '_search'].join('/');

      var es = new _emberEsAdapterUtilsEsQueryBuilder['default']({ 'size': 10000 });

      return fetch(url, {
        method: "post",
        body: JSON.stringify(es.buildQuery())
      }).then(function (resp) {
        return resp.json();
      });
    },

    filter: function filter(obj) {
      var allowed = ['size', 'from', 'query', 'page'];

      for (var i in obj) {
        if (allowed.indexOf(i) < 0) {
          delete obj[i];
        }
      }
      return obj;
    }

  });
});